// Generated by Framer (8184e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {zcGy7FXIw: {hover: true}};

const cycleOrder = ["zcGy7FXIw"];

const serializationHash = "framer-JbGza"

const variantClassNames = {zcGy7FXIw: "framer-v-d0cjj9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, imageThumbnail169, width, ...props}) => { return {...props, BcoHyg0TR: imageThumbnail169 ?? props.BcoHyg0TR} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;imageThumbnail169?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, BcoHyg0TR, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "zcGy7FXIw", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-d0cjj9", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"zcGy7FXIw"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, ...style}} {...addPropertyOverrides({"zcGy7FXIw-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image as={"figure"} background={{alt: "", fit: "fill", sizes: "max(min(578px, 100vw), 0px)", ...toResponsiveImage(BcoHyg0TR)}} className={"framer-1hoithr"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"cvHS7MaCj"}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-JbGza[data-border=\"true\"]::after, .framer-JbGza [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JbGza.framer-tzmqpw, .framer-JbGza .framer-tzmqpw { display: block; }", ".framer-JbGza.framer-d0cjj9 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 328px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 578px; will-change: var(--framer-will-change-override, transform); }", ".framer-JbGza .framer-1hoithr { flex-basis: 0px; flex-grow: 1; flex-shrink: 0; height: 329px; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-JbGza.framer-d0cjj9 { gap: 0px; } .framer-JbGza.framer-d0cjj9 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-JbGza.framer-d0cjj9 > :first-child { margin-left: 0px; } .framer-JbGza.framer-d0cjj9 > :last-child { margin-right: 0px; } }", ".framer-JbGza.framer-v-d0cjj9.hover.framer-d0cjj9 { aspect-ratio: 1.756838905775076 / 1; height: var(--framer-aspect-ratio-supported, 329px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 329
 * @framerIntrinsicWidth 578
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"IboDJ3ISh":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"BcoHyg0TR":"imageThumbnail169"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerIJjbAPFBY: React.ComponentType<Props> = withCSS(Component, css, "framer-JbGza") as typeof Component;
export default FramerIJjbAPFBY;

FramerIJjbAPFBY.displayName = "Project Thumbnail";

FramerIJjbAPFBY.defaultProps = {height: 329, width: 578};

addPropertyControls(FramerIJjbAPFBY, {BcoHyg0TR: {title: "Image (thumbnail) 16:9", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerIJjbAPFBY, [])